<template>
  <tr :class="{'persons__row-inactive': props.item.status == 'inactive'}">
    <wi-list-data-table-actions
      :config="config"
      :props="props"
    ></wi-list-data-table-actions>
    <td class="justify-left">
      {{props.item.id}}
    </td>
    <td class="justify-left">
      <strong>{{props.item.name}}</strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.active" class="persons__status persons__status--active">
        Ativo
      </span>
      <span v-else class="persons__status persons__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-left">
      <v-menu bottom left :disabled="config.mode == 'select'">
        <v-btn slot="activator" :disabled="config.mode == 'select'" outline small :loading="createReplyLoading">
          <v-icon>format_list_bulleted</v-icon>
          <span>Configurações</span>
        </v-btn>
        <v-list>
          <v-list-tile @click="openFormFields()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">format_list_bulleted</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Campos/Itens</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile :to="oToFormReplies()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Respostas</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile :loading="createReplyLoading" @click="createWiEditDialog()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Responder</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import FormReplyEditor from '@/modules/forms/config/Form/FormReplyEditor';
  export default {
    name: 'Forms',
    data: () => ({
      createReplyLoading: false
    }),
    methods: {
      openFormFields: function () {
        this.$WiListDialog({
          wiConfig: 'form-field',
          filter: {
            where: 'form_id,' + this.props.item.id
          },
          wiEditData: {
            form_id: this.props.item.id
          }
        })
      },
      oToFormReplies: function () {
        return {
          name: 'wells-list',
          params: {
            page: 'form-reply'
          },
          query: {
            where: 'form_id,' + this.props.item.id
          }
        }
      },
      createWiEditDialog: async function () {
        this.createReplyLoading = true
        const config = await FormReplyEditor(this.props.item.id)
        const wiConfig = 'form-' + this.props.item.id
        this.$store.commit('pages/ADD_CONFIG', {
          name: wiConfig,
          config: config
        })
        this.$WiEditDialog({
          wiConfig: wiConfig,
          data: {},
          redirectOnSubmit: false,
          onSubmit: this.config.refresh
        })
        this.createReplyLoading = false
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .persons__row-inactive {
    background: rgb(209, 209, 209);
  }
  .persons__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .persons__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .persons__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>